import { createSlice } from '@reduxjs/toolkit'

export const stateCallSlice = createSlice({
    name: 'stateCall',
    initialState: {
        runtimeState : {}
    },

    reducers: {   
        addStateCall : (state, action) => {
            state.runtimeState = (action.payload);
        }
    }
});

export const { addStateCall } = stateCallSlice.actions;

export default stateCallSlice.reducer