import { configureStore } from '@reduxjs/toolkit'
import CallReducer from './reducers/call';
import UserReducer from './reducers/user';
import StateCallReduсer from './reducers/stateCall';
import AgentReduсer from './reducers/agent';
import QueueReduсer from './reducers/queue';

export default configureStore({
  reducer: {
      call : CallReducer,
      user : UserReducer,
      stateCall : StateCallReduсer,
      agent : AgentReduсer,
      queue : QueueReduсer
  },
});