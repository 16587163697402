import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { fetchChangeQueue } from '../../../network/SettingsNetwork';

function FormChangeQueue({ onClose }) {

	const navigate = useNavigate();

	// Получаем ИД очери из браузерной строки
    const { queue_id } = useParams();
	
	// Храним состояние модального окна 
	const [show, setShow] = useState(false);
	// Управляем модальным окном Отрыть/Закрыть
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	// Работаем с состояниями Redux
	const queueDetail = useSelector((state) => state.queue.queueDetail)
	console.log("queueDetail : ", queueDetail);

	// Храним состояние формы
	const [form, setForm] = useState( {...queueDetail} );

	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	useEffect(() => {
        setForm({...queueDetail});
    },[show]);

	// Изменяем очередь
	async function changeQueue(e) {

		// Проверяем поле на удаление пользователя
		form.is_deleted === "on" ? form.is_deleted = "Y" : form.is_deleted = "N";

		e.preventDefault();
		console.log("FETCH : ", form);
		await fetchChangeQueue(form);
		if(form.is_deleted === "Y"){
			navigate(`/settings/queue/list`);
		}
		handleClose();
		onClose();
	}

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Изменить очередь
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер изменения очереди</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form onSubmit={ changeQueue }>
						<Form.Label>Номер очереди</Form.Label>
							<Form.Control
								id="queue_num"
								type="text"
								defaultValue={ queueDetail.queue_num  }
								onChange={ handleChangeForm }
							/>
							<br />
						<Form.Label>Название очереди</Form.Label>
							<Form.Control
								id="queue_name"
								type="text" 
								defaultValue={ queueDetail.queue_name }
								onChange={ handleChangeForm }
							/>
							<br />
						<Form.Label>Ссылка на сценарий</Form.Label>	
							<Form.Control 
								id="queue_scenario"
								type="text" 
								defaultValue={ queueDetail.queue_scenario }
								onChange={ handleChangeForm }
							/>
							<br />
						<Form.Label>Таймаут сценария</Form.Label>
						<Form.Control
							id="scenario_timeout"
							type="text" 
							defaultValue={ queueDetail.scenario_timeout }
							onChange={ handleChangeForm }
						/>
							<br />
						<Form.Check
							id="is_deleted"
							style={{color : "#e74c3c"}}
							type="switch"
							label="Удалить очередь"
							onChange={ handleChangeForm }
						/>
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit" 
							value="Изменить"
						/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormChangeQueue;