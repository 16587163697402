import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { fetchChangeAgent } from "../../../network/SettingsNetwork";


function FormChange({ onClose }) {

	const navigate = useNavigate();

	// Получаем ID пользователя из параметров адресной строки
	const { user_id } = useParams();

	// Работаем с состояниями Redux
	const agentDetail = useSelector((state) => state.agent.agentDetail)
	console.log("AgentDetail : ", agentDetail);
	
	// Состояние для управления формой изменения
	const [form, setForm] = useState({...agentDetail});
	
	//  Используется для упралением модуля Offcanvas
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
        setForm({...agentDetail});
    },[show]);
	
	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	// Отправляем изменения на сервер
	async function sendUser(e) {

		// Проверяем поле на удаление пользователя
		form.is_deleted === "on" ? form.is_deleted = "Y" : form.is_deleted = "N";
		console.log(form.is_deleted);
		
		console.log("Hendle : ", form);
		e.preventDefault();
		await fetchChangeAgent(form);
		
		if(form.is_deleted === "Y"){
			navigate(`/settings/agent/list`);
		}

		handleClose();
		onClose();
	}

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Изменить сотрудника
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер изменения пользователя</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form onSubmit={sendUser}>
						<Form.Label>Логин</Form.Label>
						<Form.Control 
							id="login"
							type="text" 
							defaultValue={form.login}
							onChange={handleChangeForm}
						/>
							<br />
						<Form.Label>Пароль</Form.Label>
						<Form.Control
							id="pin"
							type="text"
							defaultValue={form.pin}						
							onChange={handleChangeForm}
						/>
							<br />
						<Form.Label>Роль</Form.Label>
						<Form.Select
							id="user_role"
							defaultValue={form.user_role}				
							onChange={handleChangeForm}
						>
							<option>Выберите роль</option>
							<option value="MASTER">MASTER</option>
							<option value="VISOR">VISOR</option>
							<option value="OPERATOR">OPERATOR</option>
						</Form.Select>
							<br />
						<Form.Check
							id="is_deleted"
							style={{color : "#e74c3c"}}
							type="switch"
							label="Удалить сотрудника"
							onChange={handleChangeForm}
						/>
							<br />
						<Button 
							variant="success" 
							as="input" 
							type="submit" 
							value="Изменить"
						/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormChange;