import { Container, Row, Col, Table } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { addQueueList } from "../../../store/reducers/queue";
import { fetchAllQueue } from "../../../network/SettingsNetwork";

import Breadcrumb from "../../breadcrumb/index";
import Header from "../../header/index";
import FormAddQueue from "./formAddQueue";

function Queue() {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const queueList = useSelector((state) => state.queue);

    console.log(queueList);

	useEffect(() => {
        getQueueList();
    },[]);

	const getQueueList = () => {
		fetchAllQueue({limit : 200, offset : 0}, (err, resp) => {
			if (!err) {
				dispatch(addQueueList(resp));
			}
		});
	}	

	const openDetail = (e, queue_id) => {
		e.preventDefault();
		navigate(`/settings/queue/${queue_id}`);
	}

	const mappedQueueList = queueList.queueList.map( (el) => 
	<tr key={el.queue_id}>
		<td><td><a href="#" onClick={(e) => {openDetail(e,el.queue_id)}}><i className="bi bi-pencil-square"> </i></a></td></td>
		<td>{ el.queue_num }</td>
		<td>{ el.queue_name }</td>
	</tr>);

    return (
		<Container>
			
			<Row>
                <Col>
                    <Header />
                </Col>
            </Row>
            
            <Row style={{marginTop: "10px"}}>
               <Col>
                   <Breadcrumb 
                     items={[
                         {url:"/", name: "Главный экран"},
                         {url:"/settings", name: "Настройки"},
						 {url:"", name: "Очереди"}
                     ]}
                    />
                </Col>
            </Row>

			<Row>
				<Col>
					<FormAddQueue onClose={()=>{ getQueueList(); }} />
				</Col>
			</Row>

			<Row style={{marginTop: "10px"}}>
				<Col>
					<Row>
						<Col>
							<h1>Очереди</h1>
						</Col>
					</Row>
					<Row style={{marginTop: "20px"}}>
						<Col>
							<Table striped bordered>
								<thead>
									<tr>
										<th></th>
										<th>Номер</th>
										<th>Название</th>
									</tr>
								</thead>
								<tbody>
									{ mappedQueueList }
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
    );
}

export default Queue;

