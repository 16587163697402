import { Container, Row, Col, Card} from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Header from "./header";
import imgMonitor from '../img/monitor.png';
import imgSettings from '../img/settings.png';
import imgStatistic from '../img/statistic.png';
import imgWorkPlace from '../img/workPlace.png';
import config from '../config/config.js';

function MainSrceen() {  
    const User = useSelector((state) => state.user);
    const navigate = useNavigate();

    const userRole = User.user.user_role;

    let workPlace =[];

    switch(userRole){
        case 'MASTER':
            workPlace = [
                {name : 'Рабочее место Оператора', icon : imgWorkPlace, path : '/workspace'},
                {name : 'Монитор работы Контакт-Центра', icon : imgMonitor},
                {name : 'История звонков', icon : imgStatistic, path : '/callsList?'}, 
                {name : 'Настройки', icon : imgSettings, path : '/settings'}]
            break;
        case 'OPERATOR':
            workPlace = [
                {name : 'Рабочее место Оператора', icon : imgWorkPlace, path : '/workspace'},
                {name : 'История звонков', icon : imgStatistic, path : '/callsList'}]
            break;
        case 'VISOR':
            workPlace = [
                {name : 'Рабочее место Оператора', icon : imgWorkPlace, path : '/workspace'},
                {name : 'Монитор работы Контакт-Центра', icon : imgMonitor},
                {name : 'История звонков', icon : imgStatistic, path : '/callsList'}]
            break;
        default:
            navigate('/login');
    };

    if (!config.all) {
        workPlace = [
            {name : 'История звонков', icon : imgStatistic, path : '/callsList'}
        ]
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>

            <Row xs={1} md={2} className="g-5" style={{marginTop: '10px'}}>
                {workPlace.map((i, idx) => {
                    return (
                    <Col key={idx}>
                    <a href={i.path} style={{textDecoration : 'none'}}>
                        <Card bg={'light'}>
                            <Card.Img variant="top" src={i.icon} style={{ 
                                width: '12rem', paddingLeft: '15px', paddingTop: '15px'}}/>
                            <Card.Body>
                            <Card.Text>
                                <b style={{fontSize : "30px"}}>{i.name}</b>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                    </Col>
                    ) 
                }
            )}
            </Row>
        </Container>
    );    
}    

export default MainSrceen;