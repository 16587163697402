import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "../breadcrumb";
import Header from "../header";
import { lastActivity, appState, unPaused } from '../../network/WorkSpaceNetwork';
import { addStateCall } from '../../store/reducers/stateCall';

let timerId;

function WorkSpase() {

    const dispatch = useDispatch();

    const incommingCall = useSelector((state) => state.stateCall);
    const user = useSelector((state) => state.user);
    const currentPeer = user.user.peer

    console.log("CURRENT PEER : ",  currentPeer);
    
    console.log("=========================");
    console.log("CALLS : ", incommingCall.runtimeState.runtime_calls); 
    console.log("=========================");
    
    // const [appState, setAppState] = useState({});    
    

    const tick = () => {
        appState({}, (err, resp) => {
            if (!err) {
                dispatch(addStateCall(resp));
                // console.log(resp);
                // if (resp.runtime_calls?.length) {
                //     const currentCall = (resp.runtime_calls?.filter((el) => Number(el.peer) === user.user.peer))[0];
                //     console.log("console.log(currentCall?.status_call)",currentCall?.status_call)
                //         if (currentCall?.status_call === "ANSWER") {
                            
                //             setIsInCall(true);
                //         }
                    

                // }
            }
        });
    }

    useEffect(() => {
        setLastActivity();
        console.log("start useEffect;");
        timerId = setInterval(tick, 1000);
    },[]);


    const setLastActivity = () => {
        lastActivity({}, (err) => {
            if (err) {
                alert('Что-то пошло не так');
            }
        });
    }

    const setUnpaused = () => {
        timerId = setInterval(tick, 1000);
        unPaused({}, (err) => {
            if (err) {
                alert('Что-то пошло не так');
            }
        });
    }

    const getCurrentCall = () => {
        let currentCall = {};
        if (incommingCall.runtimeState.runtime_calls?.length) {
            currentCall = (incommingCall.runtimeState.runtime_calls?.filter((el) => Number(el.peer) === user.user.peer))[0];
        }
//         cid : "79120647965"
//              
// 
// peer
// : 
// "300"
// queue
// : 
// "2210"
// scenario_url
// : 
// "https://yandex.ru/map-widget/v1/-/CBFkaYSE0A"
// status_call
// : 
// "ANSWER"
// unique_id
// : 
// "1726044100.2953"
        if (currentCall.status_call === "ANSWER" && timerId) {
            console.log("clearInterval(timerId)");
            clearInterval(timerId);
            timerId = null;
        }
        return currentCall;
    }

    return (
		<Container>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>

            <Row style={ {marginTop: '10px'} }>
                <Col>
                    <Breadcrumb 
                        items={[
                            {url:"/", name: "Главный экран"},
                            {url:"", name: "Рабочее место оператора"}
                        ]}
                    />
                </Col>
            </Row>
    
            <Row style={{marginTop: "10px"}}>
                <Col>
                    <h1>{getCurrentCall().cid ? "Вам звонит " + getCurrentCall().cid: "Ожидание вызова"}</h1>
                    <hr></hr>
                    {`${JSON.stringify(incommingCall, null, 2)}`}
                </Col>
                <Col>
                    <Button variant="outline-primary" onClick={setUnpaused} className="me-2">
                        Завершить
                    </Button>
                </Col>    
            </Row>
            <Row>
                <Col>       
                    <hr></hr>
                    {getCurrentCall().scenario_url ? <iframe 
                        title='scenarioCall' 
                        src={getCurrentCall().scenario_url}
                        width="960"
                        height="500"
                        frameborder="1">
                    </iframe> : ""}
                </Col>
            </Row>
		</Container>
    );
}

export default WorkSpase;