import fetch from 'node-fetch';
import Cookie from 'js-cookie';

// Метод получения списка всех Агентов
export async function fetchAllAgent({ limit, offset }, cb = () => {}) {

    const response = await fetch(`/api/secure/settings/agent?limit=${limit}&offset=${offset}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод получения Деталей Агента
export async function fetchAgentDetail({ user_id }, cb = () => {}) {

    const response = await fetch(`/api/secure/settings/agent/${user_id}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод Добавления Агента
export async function fetchAddAgent({ login, pin, user_role }, cb = () => {}) {

    const response = await fetch(`/api/secure/settings/agent/`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        },
        body : JSON.stringify({login, pin, user_role})
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод изменения Агента
export async function fetchChangeAgent({ user_id, login, pin, user_role, is_deleted }, cb = () => {}) {

    console.log("Network : ", is_deleted);

    const response = await fetch(`/api/secure/settings/agent/${user_id}`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        },
        body : JSON.stringify( { login, pin, user_role, is_deleted } )
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод получения списка всех Очередей
export async function fetchAllQueue({ limit, offset }, cb = () => {}) {
    const response = await fetch(`/api/secure/settings/queue/?limit=${limit}&offset=${offset}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод добавления Очереди
export async function fetchAddQueue({ queue_name, queue_num, queue_scenario, scenario_timeout }, cb = () => {}) {

    console.log("Network : ", "{ queue_name : ", queue_name, "queue_num : ", queue_num, "queue_scenario : ", queue_scenario, "scenario_timeout : ", scenario_timeout);

    const response = await fetch(`/api/secure/settings/queue/`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        },
        body : JSON.stringify ( { queue_name, queue_num, queue_scenario, scenario_timeout } )
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод изменение Очереди
export async function fetchChangeQueue({ queue_id, queue_num, queue_name, queue_scenario, scenario_timeout, is_deleted }, cb = () => {}) {

    console.log("Network : ", "queue_id : ", queue_id, "queue_num : ", queue_num, "queue_name : ", queue_name, "queue_scenario : ", queue_scenario, "scenario_timeout : ", scenario_timeout, "is_deleted : ", is_deleted);

    const response = await fetch(`/api/secure/settings/queue/${ queue_id }`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        },
        body : JSON.stringify ( { queue_num, queue_name, queue_scenario, scenario_timeout, is_deleted } )
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод получения деталей Очереди
export async function fetchQueueDetails({ queue_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/settings/queue/${ queue_id }`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод добавления Агента в Очередь
export async function fetchAddAgentQueue({ queue_id, user_id, user_penalty }, cb = () => {}) {
    console.log("FETCH : ", "user_id : ", user_id, "queue_id : ", queue_id, "user_penalty : ", user_penalty);
    
    const response = await fetch(`/api/secure/settings/queueuser/`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`,
        },
        body : JSON.stringify ( { queue_id, user_id, user_penalty } )
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод удаления Агента из Очереди
export async function fetchQueueAgentDelete({ queue_id, user_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/settings/queueuser/${ queue_id }/${user_id}`, {
        method : "DELETE",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};

// Метод удаления Агента из Очереди
export async function fetchChangeAgentPenalty({ queue_id, user_id, user_penalty }, cb = () => {}) {
    const response = await fetch(`/api/secure/settings/queueuser/${ queue_id }/${user_id}`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        },
        body : JSON.stringify ( { user_penalty } )
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
};