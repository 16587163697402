import { createSlice } from '@reduxjs/toolkit'

export const agentSlice = createSlice({
    name: 'Agent',
    initialState: {
        agentDetail : {
            user_id: null,
            peer: null,
            login: "",
            pin: "",
            access_token: "",
            is_deleted: "",
            user_role: "",
            last_activity_on : null,
            queueList : []
        },
        agentList : []
    },
    reducers: {
       
        addAgentList : (state, action) => {
            state.agentList = (action.payload);
        },
        addAgentDetail : (state, action) => {
            state.agentDetail = (action.payload);
        }
    }
});

export const { addAgentList, addAgentDetail } = agentSlice.actions;

export default agentSlice.reducer