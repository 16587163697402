import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { fetchAgentDetail, fetchQueueAgentDelete, fetchAllQueue } from "../../../network/SettingsNetwork";
import { addAgentDetail } from "../../../store/reducers/agent";
import { addQueueList } from "../../../store/reducers/queue";

import Header from "../../header";
import Breadcrumb from "../../breadcrumb";
import FormChangeAgent from "./formChangeAgent";
import FormAddQueueAgent from "./formAddQueueAgent";
import FormChangePenalty from "./formChangePenalty";
 
function AgentDetail() {

	// Подключаем для работы 
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Получаем ИД Агента из адресной строки
	const { user_id } = useParams();

	// Работаем с состояниями Redux
	const Agent = useSelector((state) => state.agent)
	const Queue = useSelector((state) => state.queue)
	console.log("AGENT DETAILS QUEUE LIST : ", Queue);

	// Обращаемся к базе данных и получаем данные о деталях пользователя и очередях
	useEffect(() => {
        getAgentDetail();
		getQueueList();
    },[]);

	// Методы для запроса данных об очередях и деталях агента и дальнейшее добавление данных в Redux
	const getQueueList = () => {
		fetchAllQueue({limit : 200, offset : 0}, (err, resp) => {
			if (!err) {
				dispatch(addQueueList(resp));
			}
		});
	}	
	const getAgentDetail = () => {
		fetchAgentDetail({ user_id }, (err, resp) => {
			if (!err) {
				dispatch(addAgentDetail(resp));
			}
		});
	}

	// Метод удаления пользователя из очереди
	const delQueue = (e, user_id, queue_id) => {
		e.preventDefault();
		fetchQueueAgentDelete( {queue_id, user_id}, (err) => {
			if (!err) {
				getAgentDetail();
			}
		} );

	}

	// Навигация на детали очереди в которой содержится пользователь
	const openQueueDetail = (e, queue_id) => {
		e.preventDefault();
		navigate(`/settings/queue/${queue_id}`);
	}

	// Компонент для отображения данных об агенте
	const DetailComponent = () => <>
		<td>{ Agent.agentDetail.login }</td>
		<td>{ Agent.agentDetail.pin }</td>
		<td>{ Agent.agentDetail.user_role }</td>
		</>;

	// Map для отображения списка очередей в которых содержится агент
	const queueAgentList = Agent.agentDetail.queueList.map( (el) => 
		<tr key={ el.queue_id }>
			<td><FormChangePenalty 
					queue_id={el.queue_id} 
					user_penalty={el.user_penalty} 
					queue_name={el.queue_name}
					onClose={getAgentDetail}
				/>
			</td>
			<td>{ el.queue_name }</td>
			<td><a href="#" onClick={(e) => {openQueueDetail(e,el.queue_id)}}>{ el.queue_num }</a></td>
			<td>{ el.user_penalty }</td>
			<td><a href="#" onClick={(e) => {delQueue(e,user_id,el.queue_id)}}><i className="bi bi-x-octagon-fill" style={{color : "red"}}> </i></a> </td>
		</tr>
	);

    return (
		<Container>

		<Row>
			<Col>
				<Header />
			</Col>
		</Row>

        <Row style={{marginTop: "20px"}}>
            <Col>
                <Breadcrumb 
                    items={[
                        {url:"/", name: "Главный экран"},
						{url:"/settings", name: "Настройки"},
						{url:"/settings/agent/list", name: "Пользователи"},
                        {url:"", name: `Детализация пользователя`}
                    ]}
                />
            </Col>
        </Row>

		<Row>
			<Col>
				<FormChangeAgent onClose={getAgentDetail} />
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h1>Детализация Пользователя</h1>
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
			<Row style={{marginTop: "20px"}}>
				<Col>
					<Table striped bordered>
						<thead>
							<tr>
								<th>Логин</th>
								<th>Пароль</th>
								<th>Роль</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<DetailComponent/>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			</Col>	
		</Row>	
		    <br/>
		<Row>
			<Col>
				<FormAddQueueAgent onClose={()=>{ getAgentDetail() }} />
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h3>Очереди Пользователя</h3>
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
			<Row style={{marginTop: "20px"}}>
				<Col>
					<Table striped bordered>
						<thead>
							<tr>
								<th></th>
								<th>Название</th>
								<th>Номер</th>
								<th>Приоритет</th>
								<th>Удалить</th>
							</tr>
						</thead>
						<tbody>
							{ queueAgentList }
						</tbody>
					</Table>
				</Col>
			</Row>
			</Col>
		</Row>	
		
		</Container>
    );
}

export default AgentDetail;