import { Container, Row, Col, Table, ButtonGroup, Button, InputGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { addCallList } from "../../store/reducers/call";
import { fetchCallList } from "../../network/CallNetwork";
import Breadcrumb from "../breadcrumb";
import Header from "../header";
import queryString from "query-string";
import { useSearchParams, useParams } from "react-router-dom";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

function CallsList() {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [ searchParams ] = useSearchParams();
	const Call = useSelector((state) => state.call);

	const [form, setForm] = useState({});
	console.log("QUERY STRING : ", queryString.parse(document.location.search));

	const offset = searchParams.get("offset");
	const limit = searchParams.get("limit");
	const did = searchParams.get("did");
	const cid = searchParams.get("cid");
	let dateStart = searchParams.get("dateStart");
	if (dateStart === "Invalid Date"){
		dateStart = dayjs(dateStart).format('YYYY-MM-DD');
	}
	let dateEnd = searchParams.get("dateEnd");
	if (dateEnd === "Invalid Date"){
		dateEnd = (dayjs(dateEnd).format('YYYY-MM-DD'));
	}
	const onChangeUrl = ({offset, limit, did, cid, dateStart, dateEnd}) => {
        const currentUrlObj = queryString.parse(document.location.search.slice(1));
        
		console.log("onChangeUrl : ", currentUrlObj);

		if (limit !== undefined) currentUrlObj.limit = limit;
        if (offset !== undefined) currentUrlObj.offset = offset;
		if (did !== undefined) currentUrlObj.did = did;
		if (cid !== undefined) currentUrlObj.cid = cid;
		if (dateStart !== undefined) currentUrlObj.dateStart = dateStart ? dayjs.utc(new Date(dateStart + ' 00:00:00')).toISOString() : "";
		if (dateEnd !== undefined) currentUrlObj.dateEnd = dateStart ? dayjs.utc(new Date(dateEnd + ' 00:00:00')).add(1,'day').toISOString() : "";

        navigate(`/callsList?${queryString.stringify(currentUrlObj)}`);
    
    }

	const paginateForward = () => {
        onChangeUrl({limit : 100, offset: parseInt(offset?offset:0)+100});
    }
    const paginateBackward = () => { 
        onChangeUrl({limit : 100, offset: parseInt(offset)-100});
    }

	useEffect(() => {
        getCallList();
    },[limit, offset, did, cid, dateStart, dateEnd]); 

	const openDetail = (e, call_id) => {
		e.preventDefault();
		navigate(`/${call_id}`);
	}

	const onFilters = (e) => {
		e.preventDefault();
		console.log("onFilters : ",  form);
		onChangeUrl(form);

	}

	const getCallList = () => {
		fetchCallList({limit : limit ? limit : 100, offset : offset ? offset : 0, did : did, cid : cid, dateStart : dateStart,  dateEnd : dateEnd}, (err, resp) => { 
			if (!err) {
				dispatch(addCallList(resp));
			}
		});
	}

	let paginations = '';
	if (Call.callList.length !== 0) {
		paginations = 
		<div  style={{display: "flex", justifyContent : "end"}}>
			<ButtonGroup aria-label="pagination">
				<Button variant="light" size="sm" onClick={paginateForward}> {<i class="bi bi-arrow-right"></i>}  </Button>
			</ButtonGroup>			
		</div>
	}
	if (offset > '0') {
		paginations = 
		<div  style={{display: "flex", justifyContent : "end"}}>
			<ButtonGroup aria-label="pagination">
				<Button variant="light" size="sm" onClick={paginateBackward} > <i class="bi bi-arrow-left"></i> </Button>
				<Button variant="light" size="sm" onClick={paginateForward}> <i class="bi bi-arrow-right"></i> </Button>
			</ButtonGroup>			
		</div>
	}
	if (Call.callList.length === 0) {
		paginations = 
		<div  style={{display: "flex", justifyContent : "end"}}>
			<ButtonGroup aria-label="pagination">
			<Button variant="light" size="sm" onClick={paginateBackward} > <i class="bi bi-arrow-left"></i> </Button>
			</ButtonGroup>			
		</div>
	}

	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	const mappedCallList = Call.callList.map( (el) => 
		<tr key={el.call_id}>
			<td><a href="#" onClick={(e) => {openDetail(e,el.call_id)}}><i className="bi bi-pencil-square"> </i></a></td>
			<td>{ dayjs(el.created_on).format('DD.MM.YYYY') }</td>
			<td>{ dayjs(el.created_on).format('HH:mm:ss') }</td>
			<td>{ el.call_type }</td>
			<td>{ el.cid }</td>
			<td>{ el.did }</td>
			<td>{ el.dial_status }</td>
		</tr>);

    return (
		<Container>
		<Row>
			<Col>
				<Header />
			</Col>
		</Row>

		<Row style={ {marginTop: '10px'} }>
            <Col>
                <Breadcrumb 
                    items={[
                        {url:"/", name: "Главный экран"},
                        {url:"", name: "Звонки"}
                    ]}
                />
            </Col>
        </Row>
		<Row>
			<Col>
				<Form onSubmit={onFilters}>
					<InputGroup>
						<InputGroup.Text>Фильт : </InputGroup.Text>
							<Form.Control
								type="text"
								id="did"
								defaultValue={did}
								placeholder="Введите внутренний номер сотрудника"
								onChange={handleChangeForm}
							>
							</Form.Control>
							<Form.Control
								type="text"
								id="cid"
								placeholder="Введите входящий номер жителя"
								defaultValue={cid}
								onChange={handleChangeForm}
							>
							</Form.Control>
							<Form.Control
								type="date"
								id="dateStart"
								defaultValue={dateStart}
								placeholder="Введите дату звонка"
								onChange={handleChangeForm}
							>
							</Form.Control>
							<Form.Control
								type="date"
								id="dateEnd"
								defaultValue={dateEnd}
								placeholder="Введите дату звонка"
								onChange={handleChangeForm}
							>	
							</Form.Control>
							<Button 
								variant="outline-primary"
								size="sm"
								type="submit"> Поиск </Button>
					</InputGroup>
				</Form>
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h1>Звонки</h1>
			</Col>
		</Row>
		<Row style={{marginTop: "20px"}}>
			<Col>
				<Table striped bordered>
					<thead>
						<tr>
							<th></th>
							<th>Дата</th>
							<th>Время</th>
							<th>Тип звонка</th>
							<th>Номер Жителя</th>
							<th>Номер УК</th>
							<th>Статус Звонка</th>
						</tr>
					</thead>
					<tbody>
						{mappedCallList}
					</tbody>
				</Table>
			</Col>
		</Row>
		{paginations}
		</Container>
    );
}

export default CallsList;
