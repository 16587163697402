import fetch from 'node-fetch';
import Cookie from 'js-cookie';

export async function lastActivity({}, cb = () => {}) {
    const response = await fetch(`/api/secure/workspace/`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (!response.ok) {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function appState({}, cb = () => {}) {
    const response = await fetch(`/api/secure/workspace/state`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function unPaused({}, cb = () => {}) {
    const response = await fetch(`/api/secure/workspace/unpaused`, {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    })
    if (!response.ok) {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}
