import Cookies from 'js-cookie';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainSrceen from './components/index';
import Login from "./components/login/Login";
import Logout from "./components/login/Logout";
import CallsList from './components/calls/CallsList';
import CallDetail from './components/calls/CallDetail';
import Settings from './components/settings';
import WorkSpase from './components/workspace';
import Agent from './components/settings/agent/agent';
import Queue from './components/settings/queue/queue';
import AgentDetail from './components/settings/agent/agentDetail';
import QueueDetail from './components/settings/queue/queueDetails';

function Router() {

    const router = createBrowserRouter([
        { path : "/", element : <MainSrceen />},

        { path : "/workspace", element : <WorkSpase />},

        { path : "/callsList", element : <CallsList /> },
        { path : "/:call_id", element : <CallDetail /> },
        
        { path : "/settings", element : <Settings />},
        
        { path : "/settings/agent/list", element : <Agent />},
        { path : "/settings/agent/:user_id", element : <AgentDetail />},
        
        { path : "/settings/queue/list", element : <Queue />},
        { path : "/settings/queue/:queue_id", element : <QueueDetail />},

        { path : "/login", element : <Login /> },
        { path : "/logout", element : <Logout /> },
    ]);

    if (!Cookies.get("access_token") && window.location.pathname !== "/login") {
        window.location.href = "/login";
    }

    return (
        <>
            <RouterProvider router={ router }/>
        </>
    );
}

export default Router;