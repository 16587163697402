import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'User',
    initialState: {
        user : {"default": "AA"}
    },
    reducers: {
       
        addUser : (state, action) => {
            state.user = (action.payload);
        }
    }
});

export const { addUser } = userSlice.actions;

export default userSlice.reducer