import { Container, Row, Col, Card } from "react-bootstrap";
import Breadcrumb from "../breadcrumb";
import Header from "../header";

import imgAgent from '../../img/agent.png';
import imgQueue from '../../img/queue.png';

function Settings() {

    let workPlace = [
        {name : 'Пользователи', icon : imgAgent, path : '/settings/agent/list'},
        {name : 'Очереди', icon : imgQueue, path : '/settings/queue/list'}]

    return (

        <Container>
            <Row>
                <Col>
                    <Header />
                </Col>
            </Row>
            
            <Row style={{marginTop: "10px"}}>
               <Col>
                   <Breadcrumb 
                     items={[
                         {url:"/", name: "Главный экран"},
                         {url:"", name: "Настройки"}
                     ]}
                    />
                </Col>
            </Row>

            <Row xs={1} md={2} className="g-5" style={{marginTop: '10px'}}>
                {workPlace.map((i, idx) => (
                    <Col key={idx}>
                    <a href={i.path} style={{textDecoration : 'none'}}>
                        <Card bg={'light'}>
                            <Card.Img variant="top" src={i.icon} style={{ 
                                width: '12rem', paddingLeft: '15px', paddingTop: '15px'}}/>
                            <Card.Body>
                            <Card.Text>
                                <b style={{fontSize : "30px"}}>{i.name}</b>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                    </Col>
                ))}
            </Row>
        </Container>

    );
}

export default Settings;