import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState } from "react";
import { fetchAddAgent } from "../../../network/SettingsNetwork";

function FormAddAgent(props) {
	const [show, setShow] = useState(false);
	const [form, setForm] = useState({
		login : "", 
		pin : "", 
		user_role : ""
	});

	async function createAgent(e) {
		
		e.preventDefault();
		await fetchAddAgent(form);
		handleClose();
		props.onClose();
	}

	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}
	
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Добавить пользователя
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер добавления пользователя</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form  onSubmit={createAgent} id="formCreateAgent">
						<Form.Control	
							type="text" 
							id="login"
							placeholder="Введите логин"
							onChange={handleChangeForm}
						/>
							<br />
							<Form.Control
								type="text"
								id="pin"
								placeholder="Введите пароль"
								onChange={handleChangeForm}					
							/>
							<br />
						<Form.Select 
							id="user_role"
							onChange={handleChangeForm}
						>
							<option>Выберите роль</option>
							<option value="MASTER">MASTER</option>
							<option value="VISOR">VISOR</option>
							<option value="OPERATOR">OPERATOR</option>
						</Form.Select>
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit"
							value="Добавить"/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormAddAgent;