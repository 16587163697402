import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { fetchAddAgentQueue } from "../../../network/SettingsNetwork";

function FormAddQueueAgent({ onClose }) {

    const { user_id } = useParams();

	const queue = useSelector((state) => state.queue)
	console.log("FORM ADD QUEUE : ", queue.queueList);

	const [show, setShow] = useState(false);
	const [form, setForm] = useState({
		user_id : user_id,
		queue_id : null,
		user_penalty : null
	});

	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	async function addAgentQueue(e) {
		e.preventDefault();
        console.log("FORM : ", form);
        await fetchAddAgentQueue(form);
		handleClose();
        onClose();
	}

	const mapQueueList = queue.queueList.map((el) => 
		<option value={el.queue_id} key={el.queue_id}>{ el.queue_name }</option>
	);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Добавить очередь
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер добавления очереди</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form  onSubmit={addAgentQueue}>
					<Form.Label>Выберите очередь</Form.Label>
						<Form.Select 
							id="queue_id"
							onChange={handleChangeForm}
						>
							<option></option>	
							{ mapQueueList }
						</Form.Select>
							<br />
						<Form.Label>Введите приоритет пользователя</Form.Label>
                        <Form.Control
							id="user_penalty"
                            type="text"
                            onChange={handleChangeForm}							
                        />
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit"
							value="Добавить"/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormAddQueueAgent;