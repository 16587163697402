import { Container, Row, Col, Table } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { fetchQueueDetails, fetchQueueAgentDelete, fetchAllAgent } from "../../../network/SettingsNetwork";
import { addQueueDetail } from "../../../store/reducers/queue";
import { addAgentList } from '../../../store/reducers/agent';
import FormChangePenalty from "./formChangePenalty";

import Header from "../../header";
import Breadcrumb from "../../breadcrumb";
import FormChangeQueue from './formChangeQueue';
import FormAddAgentQueue from './formAddAgentQueue';
 
function QueueDetail() {

	// Подключаем для работы 
	const navigate = useNavigate();
	const { queue_id } = useParams();
	const dispatch = useDispatch();
	
	const queue = useSelector((state) => state.queue.queueDetail);
	const agent = useSelector((state) => state.agent.agentList);

	console.log("QUEUE DETAIL : ", queue)
	console.log("QUEUE AGENT LIST : ", queue.agentList);

	useEffect(() => {
        getQueueDetails();
		getAgentList();
    },[]);

	const getQueueDetails = () => {
		fetchQueueDetails({ queue_id }, (err, resp) => {
			if (!err) {
				dispatch(addQueueDetail(resp));
			}
		});
	}
	const getAgentList = () => {
		fetchAllAgent({limit : 200, offset : 0}, (err, resp) => {
			if (!err) {
				dispatch(addAgentList(resp));
			}
		});
	}

	// Навигация на детали агента, который содержится в очереди
	const openAgentDetail = (e, user_id) => {
		e.preventDefault();
		navigate(`/settings/agent/${user_id}`);
	}
	
	const delAgent = (e, user_id, queue_id) => {
		e.preventDefault();
		console.log("DELETE : {", " USER : " ,user_id, "QUEUE : ",  queue_id, " }");
		fetchQueueAgentDelete( {queue_id, user_id}, (err) => {
			if (!err) {
				getQueueDetails();
			}
		} );

	}

	const DetailComponent = () => <>
		<td>{ queue.queue_num }</td>
		<td>{ queue.queue_name }</td>
		<td>{ queue.queue_scenario }</td>
		<td>{ queue.scenario_timeout }</td>
	</>;

	const agentsQueue = queue.agentList.map( (el) => 
		<tr key={ el.user_id }>
			<td> <FormChangePenalty 
					login={el.login}
					user_id={el.user_id} 
					user_penalty={el.user_penalty}
					onClose={() => { getQueueDetails() }}
				/> 
			</td>
			<td><a href="#" onClick={(e) => {openAgentDetail(e,el.user_id)}}>{ el.login }</a></td>
            <td>{ el.user_penalty }</td>
            <td><a href="#" onClick={(e) => {delAgent(e,el.user_id,queue.queue_id)}}><i className="bi bi-x-octagon-fill" style={{color : "red"}}> </i></a></td>
		</tr>
	);

    return (
		<Container>

		<Row>
			<Col>
				<Header />
			</Col>
		</Row>

        <Row style={{marginTop: "20px"}}>
            <Col>
                <Breadcrumb 
                    items={[
                        {url:"/", name: "Главный экран"},
						{url:"/settings", name: "Настройки"},
						{url:"/settings/queue/list", name: "Очереди"},
                        {url:"", name: `Детализация пользователя`}
                    ]}
                />
            </Col>
        </Row>

        <Row>
			<Col>
				<FormChangeQueue onClose={() => { getQueueDetails() }}/>
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h1>Детализация Очереди</h1>
			</Col>
		</Row>

		<Row style={{marginTop: "10px"}}>
			<Col>
			<Row style={{marginTop: "20px"}}>
				<Col>
					<Table striped bordered>
						<thead>
							<tr>
								<th>Номер очереди</th>
								<th>Название транка</th>
								<th>Сценарий</th>
								<th>Таймаут</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<DetailComponent/>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			</Col>
		</Row>	

		<Row style={{marginTop: "10px"}}>
			<Col>
				<h3>Пользователи в очереди</h3>
			</Col>
		</Row>
        
        <Row>
            <Col>
                <FormAddAgentQueue onClose={() => { getQueueDetails() }} />
            </Col>
        </Row>
		
        <Row style={{marginTop: "10px"}}>
			<Col>
			<Row style={{marginTop: "20px"}}>
				<Col>
					<Table striped bordered>
						<thead>
							<tr>
								<th></th>
								<th>Пользователь</th>
								<th>Приоритет</th>
                                <th>Удалить пользователя</th>
							</tr>
						</thead>
						<tbody>
							{ agentsQueue }
						</tbody>
					</Table>
				</Col>
			</Row>
			</Col>
		</Row>	

		</Container>
    );
}

export default QueueDetail;