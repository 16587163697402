import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState } from "react";
import { fetchAddQueue } from '../../../network/SettingsNetwork';

function FormAddQueue({onClose}) {

	// Храним состояние модального окна 
	const [show, setShow] = useState(false);
	
	// Управляем модальным окном Отрыть/Закрыть
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	// Храним состояние формы
	const [form, setForm] = useState({
		queue_name : "",
		queue_num : "",
		queue_scenario : "",
		scenario_timeout : "",
		is_deleted : "N"
	});

	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	// Отправляем данные для создания очереди
	async function addQueue(e) {
		e.preventDefault();
		console.log(form);
		await fetchAddQueue(form);
		handleClose();
		onClose();
	}

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Добавить очередь
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер добавления очереди</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form onSubmit={addQueue}>
					<Form.Control 
							id="queue_num"
							type="text"
							placeholder="Введите номер очереди"
							onChange={handleChangeForm}
						/>
							<br />
						<Form.Control
							id="queue_name"
							type="text"
							placeholder="Введите название очереди"
							onChange={handleChangeForm}
						/>
							<br />
						<Form.Control
							id="queue_scenario"
							type="text"
							placeholder="Введите ссылку на сценарий"
							onChange={handleChangeForm}
						/>
							<br />
						<Form.Control
							id="scenario_timeout"
							type="text"
							placeholder="Введите таймаут сценария"
							onChange={handleChangeForm}
						/>
							<br />
						<Button 
							variant="success" 
							as="input" 
							type="submit" 
							value="Добавить"
						/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormAddQueue;