import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router";
import { fetchChangeAgentPenalty } from "../../../network/SettingsNetwork";

function FormChangePenalty({queue_id, user_penalty, queue_name, onClose}) {

    const { user_id } = useParams();
    
	const [show, setShow] = useState(false);
	const [form, setForm] = useState({
        queue_id : queue_id,
        user_id : user_id,
        user_penalty : null
    });

	async function changeAgentPenalty(e) {
		e.preventDefault();
        console.log(form);
        await fetchChangeAgentPenalty(form);
		handleClose();
		onClose();
	}

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return(
		<Container>
			<Button variant="link" onClick={handleShow}>
                <i className="bi bi-pencil-square"> </i>
			</Button>

			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер изменения очереди</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form  onSubmit={changeAgentPenalty}>
                        <Form.Label>Название очереди</Form.Label>
						<Form.Control	
							type="text" 
							defaultValue={queue_name}
                            readOnly
						/>
							<br />
                        <Form.Label>Приоритет пользователя</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={user_penalty}
                            onChange={(e) => {
                                console.log(e);
                                setForm({
                                    ...form,
                                    user_penalty : e.target.value,
                                })
                            }}							
                        />
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit"
							value="Изменить"/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormChangePenalty;