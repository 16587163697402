import fetch from 'node-fetch';
import Cookie from "js-cookie";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export async function fetchCallList({ limit, offset, did , cid, dateStart, dateEnd}, cb = () => {}) {

    console.log(`limit : ${limit}, offset : ${offset}, did : ${did}, cid : ${cid}, dateStart : ${dateStart},  dateEnd : ${dateEnd}`);

    function createQueryString(params) {
        const urlParams = new URLSearchParams();
    
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key] !== undefined && params[key] !== null) {
                urlParams.append(key, params[key]);
            }
        }
    
        return urlParams.toString();
    }
    

    const params = {
        limit : limit,
        offset : offset, 
        did : did,
        cid : cid,
        dateStart : dateStart,
        dateEnd : dateEnd
    };
    
    const queryString = createQueryString(params);
    console.log(queryString);

    const response = await fetch(`/api/secure/call?${queryString}`, { 
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchCallDetail({ call_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchCallDetailList({ call_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}/detail_list`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}

export async function fetchRecCall({ call_id, rec_id }, cb = () => {}) {
    const response = await fetch(`/api/secure/call/${call_id}/detail_list/${rec_id}`, {
        method : "GET",
        headers : {
            'Content-Type' : 'application/json',
            'authorization' : `Bearer ${Cookie.get("access_token")}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        cb(null, data)
    } else {
        if (response.status === 401) window.location.href = "/logout";
        cb(response.status + " " + response.statusText)
    }
}