import { Container, Row, Col, Table} from "react-bootstrap";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import Header from "../header";
import Breadcrumb from "../breadcrumb";
import { addCall, addCallDetailList } from "../../store/reducers/call";
import { fetchCallDetail, fetchCallDetailList } from "../../network/CallNetwork";


	function CallDetail() {

		const { call_id, rec_id } = useParams();
		
		const dispatch = useDispatch();
		const Call = useSelector((state) => state.call);
	
		useEffect(() => {
			getCallDetail();
			getCallDetailList();
			getFetchRecCall();
		},[]);
	
		const getCallDetail = () => {
			fetchCallDetail({ call_id }, (err, resp) => {
				if (!err) {
					dispatch(addCall(resp));
				};
			});
		}
	
		const getCallDetailList = () => {
			fetchCallDetailList({ call_id }, (err, resp) => {
				if (!err) {
					dispatch(addCallDetailList(resp));
				};
			});
		}
	
		const getFetchRecCall = () => {
			// fetchRecCall({ call_id, rec_id }, (err, resp) => {
			// 	if (!err) {
			// 		dispatch(addCallDetailList(resp));
			// 	};
			// });
		}
			
		const DetailComponent = () => <>
			<td>{dayjs(Call.call.created_on).format('DD.MM.YYYY')}</td>
			<td>{dayjs(Call.call.created_on).format('HH:mm:ss')}</td>
			<td>{Call.call.call_type}</td>
			<td>{Call.call.context}</td>
			<td><audio controls src={`/api/file/${call_id}`}></audio></td>
			<td>{Call.call.cid}</td>
			<td>{Call.call.did}</td>
			<td>{Call.call.dial_status}</td>
			<td>{Call.call.queue_did}</td>
			<td>{Call.call.queue}</td>
			<td>{dayjs(Call.call.queue_entered_on).format('HH:mm:ss')}</td>
			<td>{ Call.answered_on === null ? Call.answered_on : dayjs(Call.answered_on).format('HH:mm:ss') }</td>
			{/* <td>{dayjs(Call.call.answered_on).format('HH:mm:ss')}</td> */}
			<td>{dayjs(Call.call.finished_on).format('HH:mm:ss')}</td>
		</>;
	
	
		const mappedCallList = Call.callDetailList.map( (el) => 
			<tr key={el.call_id}>
				<td>{ dayjs(el.created_on).format('DD.MM.YYYY') }</td>
				<td>{ dayjs(el.created_on).format('HH:mm:ss') }</td>
				<td>{ el.call_type }</td>
				<td>{ el.context }</td>
				<td><audio controls src={`/api/file/${call_id}`}></audio></td>
				<td>{ el.cid }</td>
				<td>{ el.did }</td>
				<td>{ el.dial_status }</td>
				<td>{ el.queue_did }</td>
				<td>{ el.queue }</td>
				<td>{ el.answered_on === null ? el.answered_on : dayjs(el.answered_on).format('HH:mm:ss') }</td>
				<td>{ dayjs(el.finished_on).format('HH:mm:ss') }</td>
			</tr>); 

    return (
		<Container>
		<Row>
			<Col>
				<Header />
			</Col>
		</Row>
		<Row style={{marginTop: "10px"}}>
			<Col>
				<h1>Детализация звонка</h1>
			</Col>
		</Row>
        <Row>
            <Col>
                <Breadcrumb 
                    items={[
                        {url:"/", name: "Главный экран"},
						{url:"/callsList", name: "Звонки"},
                        {url:"", name: `Детализация звонка ${call_id}`}
                    ]}
                />
            </Col>
        </Row>
		<Row>
			<Col>
				<h3>Звонок</h3>
				<Table striped bordered>
					<thead>
						<tr>
							<th>Дата</th>
							<th>Время</th>
							<th>Тип звонка</th>
							<th>Контекст</th>
							<th>Запись</th>
							<th>Номер Жителя</th>
							<th>Номер УК</th>
							<th>Статус Звонка</th>
							<th>Оператор</th>
							<th>Очередь</th>
							<th>В очередь</th>
							<th>Ответ</th>
							<th>Отбой</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<DetailComponent/>
						</tr>
					</tbody>
				</Table>	
			</Col>
		</Row>
		<Row style={{marginTop: "20px"}}>
			<Col>
				<h3>Детали</h3>
				<Table striped bordered>
						<thead>
							<tr>
								<th>Дата</th>
								<th>Время</th>
								<th>Тип звонка</th>
								<th>Контекст</th>
								<th>Запись</th>
								<th>Номер Жителя</th>
								<th>Номер УК</th>
								<th>Статус Звонка</th>
								<th>Оператор</th>
								<th>Очередь</th>
								<th>Ответ</th>
								<th>Отбой</th>
							</tr>
						</thead>
						<tbody>
							{mappedCallList}
						</tbody>
					</Table>
			</Col>
		</Row>
		</Container>
    );
}

export default CallDetail;
