import { createSlice } from '@reduxjs/toolkit'

export const queueSlice = createSlice({
    name: 'Queue',
    initialState: {
        queueDetail : {
            queue_id: null,
            queue_name: "",
            queue_num: "",
            queue_scenario: "",
            is_deleted: "",
            scenario_timeout: null,
            agentList : []
        },
        queueList : []
    },
    reducers: {
       
        addQueueList : (state, action) => {
            state.queueList = (action.payload);
        },
        addQueueDetail : (state, action) => {
            state.queueDetail = (action.payload);
        }
    }
});

export const { addQueueList, addQueueDetail } = queueSlice.actions;

export default queueSlice.reducer