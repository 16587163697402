import { Container, Button, Offcanvas, Form } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router";
import { fetchChangeAgentPenalty } from "../../../network/SettingsNetwork";


function FormChangePenalty( { login, user_id, user_penalty, onClose } ) {
    
	const { queue_id } = useParams();

	const [show, setShow] = useState(false);
	const [form, setForm] = useState({
        queue_id : queue_id,
        user_id : user_id,
        user_penalty : null
    });

	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	async function changeAgentPenalty(e) {
		e.preventDefault();
        console.log("FETCH : ", form);
        await fetchChangeAgentPenalty(form);
		handleClose();
		onClose();
	}

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return(
		<Container>
			<Button variant="link" onClick={handleShow}>
                <i className="bi bi-pencil-square"> </i>
			</Button>

			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер изменения очереди</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form  onSubmit={changeAgentPenalty}>
                        <Form.Label>Пользователь</Form.Label>
						<Form.Control	
							type="text" 
							defaultValue={ login }
                            readOnly
						/>
							<br />
                        <Form.Label>Приоритет пользователя</Form.Label>
                        <Form.Control
							id="user_penalty"
                            type="text"
                            defaultValue={ user_penalty }
                            onChange={handleChangeForm}							
                        />
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit"
							value="Изменить"/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 
export default FormChangePenalty;