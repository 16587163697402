import { Container, Button, Offcanvas, Form, ListGroup } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { fetchAddAgentQueue } from "../../../network/SettingsNetwork";


function FormAddAgentQueue( { onClose } ) {

	// Получаем ИД очери из браузерной строки
    const { queue_id } = useParams();
	
	// Подключаем Redux для работы с списком Агентов
	const agentList = useSelector((state) => state.agent.agentList);
	console.log("FROM ADD AGENT : ", agentList);

	// Храним состояние модального окна 
	const [show, setShow] = useState(false);
	// Управляем модальным окном Отрыть/Закрыть
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Храним состояние формы
	const [form, setForm] = useState({
		queue_id : queue_id,
		user_id : "",
		user_penalty : ""
	});

	// Считываем изменения в Form и записываем их в хранимое состояние
	const handleChangeForm = (e) => {
		console.log(e.target.id, e.target.value);
		const a = form;
		a[e.target.id] = e.target.value;
		setForm(a);
	}

	// Добавляем пользователя в очередь
	async function addAgentQueue(e) {
		e.preventDefault();
        console.log("FETCH : ", form);
        await fetchAddAgentQueue(form);
		handleClose();
		onClose();
	}

	// Map список агентов для выбора нужного
	const mapAgentList = agentList.map((el) => 
	<option value={el.user_id} key={el.user_id}>{ el.login }</option>
);

	return(
		<Container>
			<Button variant="outline-primary" onClick={handleShow} className="me-2">
				Добавить пользователя
			</Button>
			<Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Мастер добавления пользователя</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form  onSubmit={ addAgentQueue }>
						<Form.Label>Выберите пользователя</Form.Label>
						<Form.Select
							id="user_id"	
							type="text" 
							onChange={ handleChangeForm }
						>	
							<option></option>
							{ mapAgentList } 
						</Form.Select>	
							<br />
                        <Form.Control
							id="user_penalty"
                            type="text"
                            placeholder="Введите приоритет пользователя"
							onChange={ handleChangeForm }							
                        />
							<br />
						<Button 
							variant="success"
							as="input"
							type="submit"
							value="Изменить"/>{' '}
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
	} 

export default FormAddAgentQueue;